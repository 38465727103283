@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-roman-webfont.eot');
    src: url('../fonts/crimson-roman-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-roman-webfont.woff2') format('woff2'),
         url('../fonts/crimson-roman-webfont.woff') format('woff'),
         url('../fonts/crimson-roman-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-italic-webfont.eot');
    src: url('../fonts/crimson-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-italic-webfont.woff2') format('woff2'),
         url('../fonts/crimson-italic-webfont.woff') format('woff'),
         url('../fonts/crimson-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-semibold-webfont.eot');
    src: url('../fonts/crimson-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-semibold-webfont.woff2') format('woff2'),
         url('../fonts/crimson-semibold-webfont.woff') format('woff'),
         url('../fonts/crimson-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-semibolditalic-webfont.eot');
    src: url('../fonts/crimson-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/crimson-semibolditalic-webfont.woff') format('woff'),
         url('../fonts/crimson-semibolditalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;

}

@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-bold-webfont.eot');
    src: url('../fonts/crimson-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-bold-webfont.woff2') format('woff2'),
         url('../fonts/crimson-bold-webfont.woff') format('woff'),
         url('../fonts/crimson-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Crimson';
    src: url('../fonts/crimson-bolditalic-webfont.eot');
    src: url('../fonts/crimson-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/crimson-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/crimson-bolditalic-webfont.woff') format('woff'),
         url('../fonts/crimson-bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;

}

.template-front-page {
  .page-body {
    margin-bottom: 0;
  }
  
  .hero-primary {
    margin-bottom: $space-double;

    @include breakpoint($break-large) {
      margin-bottom: 0;
    }
  }

  .hero-primary + .section-fullwidth {
    padding-top: 0;

    .section-body {
      @include breakpoint($break-large) {
        padding-top: $space * 1.5;
      }
    }
  }
}

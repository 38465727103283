.term-links {
  margin: 0;


  li:last-child .link {
    margin-right: 0;
  }

  .link {
    display: inline-block;
    border-radius: 999999px;
    border: 1px solid $color-grey-medium;
    color: $color-grey-medium;
    font-size: em($fz-h6);
    padding: 5px 20px;
    font-family: $ff-secondary;
    font-weight: $fw-bold;
    text-decoration: none;
    margin-right: $space-one-fourth;
    margin-bottom: $space-one-fourth;

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $white;
      border-color: $color-primary;
    }
  }
}

// Typography term
.term-title {
  @extend .title-boxed;
}

.term-label {
  text-transform: uppercase;
  background-color: $color-secondary;
  font-family: $ff-secondary;
  border-radius: 2px;
  display: inline-block;
  color: $white;
  padding: 6px $space-one-fourth 4px;
  position: relative;
  font-size: em(11);
  line-height: .95;
  text-decoration: none;
}

@include breakpoint($break-small) {
  .term-inline {
    top: -.3em;
  }
}
.term-inline {
  @extend .term-label;
  margin-right: $space-one-fourth;
  // top: -.3em;
}
  @include breakpoint($break-large) {
  .term-inline {
    top: 0;
  }
}

.term .inner,
.term-inline {
  background-color: $color-secondary;
  color: $white;
}

$categories: (
  politikk: $dark-blue,
  helse: $light-blue,
  miljø: $green,
  sport: $yellow,
  idrett: $yellow,
  samfunn: $orange,
  kultur: $pink,
  teknologi: $black,
  økonomi: $grey-777777
);

@each $category, $bgcolor in $categories {
  .template-category.category-#{$category} .main-header,
  .term .inner.term-#{$category},
  .term-inline.term-#{$category} {
    background-color: $bgcolor;
  }
}

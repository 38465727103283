.icon-facebook {
  @include sprite(facebook);
}

.icon-facebook-white {
  @include sprite(facebook-white);
}

.icon-search-black {
  @include sprite(search-black);
}

.icon-search-white {
  @include sprite(search-white);
}

.icon-twitter {
  @include sprite(twitter);
}

.icon-twitter-white {
  @include sprite(twitter-white);
}

.icon-viral {
  @include sprite(viral);
}

.icon-chevron-up-small {
  @include sprite(chevron-up-small);
}

.circle-icon {
  $size: 38px;

  background-color: $black;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  vertical-align: middle;
  color: white;
  border-radius: 50%;
  display: inline-block;

  [class*="icon-"] {
    vertical-align: inherit;
    position: relative;
    top: 1px;
  }

  .mod-circle-icon_large & {
    $size-large: 55px;
    width: $size-large;
    height: $size-large;
    line-height: $size-large;
  }

  .mod-circle-icon_outline & {
    background-color: transparent !important;
    border: 1px solid $black;
    line-height: $size - 1;

    @at-root .mod-circle-icon_outline.mod-circle-icon_large .circle-icon {
      border-width: 2px;
      line-height: 55px - 2; // Could not use $size-large because of out of scope (Using `@at-root`)
    }

  }
}

.page-footer {
  padding: $space 0;
  background-color: $bg-grey-light;
  font-family: $ff-secondary;
  font-size: em(11);
  color: $color-grey-light;
  text-align: center;

  @include breakpoint($break-large) {
    padding: ($space * 1.5) 0 $space-double;
  }

  > .container {
    margin-right: auto;
    margin-left: auto;
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $color-primary;
    }
  }
}

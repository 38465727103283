.section {
  margin-bottom: $space;

  @include breakpoint($break-large) {
    margin-bottom: $space-double;
  }

  + .section {
    margin-top: $space;

    @include breakpoint($break-large) {
      margin-top: $space-double;
    }
  }
}

.section-fullwidth {
  padding-bottom: $space-half;
  overflow: hidden;

  @include breakpoint($break-large) {
    background-color: $bg-grey-light;
    padding-bottom: $space;
  }

  .section-body {
    $frame: 48px;

    @include breakpoint($break-large) {
      background-color: $white;
      border: 1px solid $border-color-medium;
      margin-right: -$frame;
      margin-left: -$frame;
      padding-right: $frame;
      padding-left: $frame;
      padding-top: 36px;
      padding-bottom: 59px;
    }
  }
}

.section-title {
  color: $color-primary;
  text-align: center;
  font-size: em(30);
  margin-bottom: $space;
}

.section-footer {
  margin-top: $space * 1.5;
}

.section-mostread {
  .section-header {
    text-align: center;
  }

  .section-title {
    position: relative;
    display: inline-block;
    padding-left: 68px;

    &:before {
      content: "";
      @include sprite(viral);
      position: absolute;
      left: 0;
      top: -7px;
    }
  }
}

%gallery {
  @extend .clearfix;
  clear: both;

  .grid-item {
    + .grid-item {
      margin-top: gutter($layout-small);

      @include breakpoint($break-medium) {
        margin-top: gutter();
      }
    }
  }
}

.gallery-three {
  @extend %gallery;

  .grid-item {
    @include breakpoint($break-medium $break-large) {
      @include gallery(6);

      &:nth-child(-n+2) {
        margin-top: 0 !important;
      }
    }

    @include breakpoint($break-large) {
      @include gallery(4);

      &:nth-child(-n+3) {
        margin-top: 0 !important;
      }
    }
  }
}

.gallery-four {
  @extend %gallery;

  .grid-item {
    @include breakpoint($break-medium $break-large) {
      @include gallery(6);

      &:nth-child(-n+2) {
        margin-top: 0 !important;
      }
    }

    @include breakpoint($break-large) {
      @include gallery(3);

      &:nth-child(-n+4) {
        margin-top: 0 !important;
      }
    }
  }
}

// Grid modifier: Add border between each grid-item on small screens
.mod-horizontal-border_grid {
  @include breakpoint($break-large) {
    .grid-item {
      position: relative;

      &:before {
        content: "";
        width: 100%;
        border-top: 1px solid $border-color-dark2;
        position: absolute;
        top: -50px;
      }

      + .grid-item {
        margin-top: 100px;
      }
    }

    .gallery-two {
      .grid-item:nth-child(-n+2) {
        &:before {
          content: none;
        }
      }
    }

    .gallery-three {
      .grid-item:nth-child(-n+3) {
        &:before {
          content: none;
        }
      }
    }

    .gallery-four {
      .grid-item:nth-child(-n+4) {
        &:before {
          content: none;
        }
      }
    }
  }
}

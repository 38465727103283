.expandable-media {
  @include breakpoint($break-large) {
    position: relative;
    transition: all .25s ease-in-out;
    // clear: both;
  }

  &.expanded {
    width: 100%;

    .toggle:before {
      transform: rotate(45deg);
    }
  }

  .toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;

    @include breakpoint($break-large) {
      display: block;
    }

    &:before {
      content: "+";
      // @include sprite(circle-plus);
      width: 31px;
      height: 31px;
      line-height: 35px;
      border-radius: 50%;
      font-size: 20px;
      color: #fff;
      text-align: center;
      background-color: #000;
      opacity: .6;
      position: absolute;
      top: $space-one-fourth;
      right: $space-one-fourth;
      transition: all .25s ease-in-out;
    }

    &:hover,
    &:focus {
      &:before {
        opacity: 1;
      }
    }
  }

  &.aligncenter,
  &.alignnone {
    .toggle {
      display: none;
    }
  }
}

.richmedia {
  margin: $space-double auto;
  max-width: 1108px;
  overflow: hidden;

  @include breakpoint($break-large) {
    // margin: ($space * 1.5) 0;
  }

  iframe {
    width: 100%;
    height: 606px + 50; // Variable height depending on media
  }
}

.video-wrapper {
  margin-top: $space-half;
  margin-bottom: $space-half;

  @include breakpoint($break-large) {
    margin-top: $space;
    margin-bottom: $space;
  }
}

.template-article {
  .page-body {
    margin-bottom: 0;

    > .post {
      border-bottom: 1px solid $border-color;
    }
  }

  .hero-figure {
    margin-bottom: $space-three-fourth;

    @include breakpoint($break-large) {
      margin-bottom: 64px;
    }
  }

  .main-title {
    margin-bottom: 0;
    margin-top: 0;

    @include breakpoint($break-large) {
      @include pre(2);
      @include post(2);
      text-align: center;
    }
  }

  .main-lead {
    margin-top: $space-three-fourth;

    @include breakpoint($break-large) {
      @include pre(1);
      @include post(1);
      text-align: center;
      margin-top: $space;
    }
  }

  .main-header {
    .meta {
      margin-top: $space-three-fourth;
      margin-bottom: $space;

      @include breakpoint(max-width $break-large) {
        li {
          display: block;
          margin-right: 0;
        }
      }

      @include breakpoint($break-large) {
        margin-top: $space * 1.5;
        margin-bottom: $space * 1.5;
        text-align: center;
      }
    }
  }

  .main-body {
    @include breakpoint($break-large) {
      @include pre(1);
      @include post(1);
    }

    @include breakpoint($break-xlarge) {
      margin-right: auto;
      margin-left: auto;
      width: span(7);
    }

    .alignleft,
    .alignright,
    .contentbox {
      @include breakpoint($break-large) {
        width: span(5 of 10);
      }

      @include breakpoint($break-xlarge) {
        width: span(3 of 7) + (gutter(of 7) * 2);
      }
    }

    .alignright,
    .contentbox {
      @include breakpoint($break-large) {
        clear: right;
        float: right;
        margin-left: $space;
        margin-bottom: $space;
        margin-right: -(span(1 of 10) + gutter(of 10));
      }

      @include breakpoint($break-xlarge) {
        margin-right: -(span(1 of 7) + gutter(of 7));
      }
    }


    .expandable-media {
      &.expanded {
        &.alignright {
          @include breakpoint($break-large) {
            width: 100% + (span(1 of 10) + gutter(of 10));
          }

          @include breakpoint($break-xlarge) {
            width: 100% + (span(1 of 7) + gutter(of 7));
          }
        }

        &.alignleft {
          @include breakpoint($break-large) {
            width: 100%;
          }
        }
      }
    }

    .alignleft,
    .alignright,
    .aligncenter {
      @include breakpoint(max-width $break-large) {
        margin-right: -$gutter;
        margin-left: -$gutter;

        figcaption {
          margin-right: $gutter;
          margin-left: $gutter;
        }
      }
    }

    blockquote {
      clear: both;

      @include breakpoint($break-large) {
        @include pre(1 of 10);
        @include post(1 of 10);
      }

      @include breakpoint($break-xlarge) {
        @include pre(.5 of 7);
        @include post(.5 of 7);
      }
    }

  }

  .main-footer {
    margin-top: $space * 1.5;
    margin-bottom: $space * 1.5;

    @include breakpoint($break-large) {
      @include pre(1);
      @include post(1);
      margin-top: $space-double;
      margin-bottom: $space-double;
    }

    @include breakpoint($break-xlarge) {
      margin-left: span(3);
      width: span(7);
    }

    > :last-child {
      margin-bottom: 0;
    }

    .term-links {
      margin-bottom: -($space-one-fourth);
      display: inline-block;

      li {
        display: inline-block;
        float: none;
      }
    }

    .social-share {
      margin-top: $space * 1.5;
    }
  }

  .main-aside {
    .section {
      @include breakpoint($break-large) {
        margin-left: span(3);
        width: span(7);
      }
    }

    .section-header {
      overflow: hidden;
    }

    .section-title {
      text-align: left;
      font-size: em($fz-h2);
      color: $base-color;
      position: relative;
      display: inline-block;
      margin-top: 0;
      margin-bottom: $space;

      @include breakpoint($break-large) {
        margin-bottom: $space * 1.5;
      }

      &:after {
        content: "";
        border-top: 1px dotted $border-color-dark;
        display: block;
        width: 1000px;
        position: absolute;
        top:  13px;
        left: 100%;
        margin-left: $space-one-fourth;
      }
    }
  }
}

.meta {
  display: block;
  text-align: left;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  font-family: $ff-secondary;
  font-size: em(13);
  color: $color-grey-light;

  li {
    display: inline-block;
    margin-right: $space-one-fourth;
  }

  .author {
    color: $color-grey-medium;
    font-size: 16px;
    line-height: 1;

    span {
      color: #ff6c08;
    }
  }
}

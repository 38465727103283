.hero-figure {
	margin-top: 0;

	@include breakpoint($break-large) {
		position: relative;
	}

	.image {
		display: block;
		height: 204px;
		max-height: 819px;
		background-position: center;
		background-size: cover;

		@include breakpoint($break-medium) {
			height: 311px;
		}

		@include breakpoint($break-large) {
			height: 424px;
		}

		@include breakpoint($break-xlarge) {
			height: 587px;
		}

		&.image {
			height: 59vw;
		}
	}

	figcaption {
		margin-right: $gutter;
		margin-left: $gutter;
		max-width: 40em;

		@include breakpoint($break-large) {
			margin-right: 0;
			margin-left: 0;
			position: absolute;
			bottom: $space-half;
			right: $space;
			color: $white;
			text-align: right;

		}
	}

	.credit {
		margin-top: $space-one-eight;

		@include breakpoint($break-large) {
			color: inherit;
		}
	}
}

.hero {
	position: relative;
	// padding-top: $space-double;

	@include breakpoint($break-large) {
		color: white;
		padding-top: $space-double;
		padding-bottom: $space-double * 2;
	}

	.link-wrapper {
		@include breakpoint($break-large) {
			position: relative;
		}

		&:hover,
		&:focus {
			.title {
				text-decoration: underline;
			}
		}
	}

	.title {
		text-align: center;
		font-family: $ff-secondary;
		padding-top: $space-double;
		margin-top: 0;
		margin-bottom: $space-double;
		font-size: em($fz-h1-small);
		color: white;

		@include breakpoint($break-large) {
			font-size: em($fz-h1);
			margin-bottom: $space;
		}
	}

	a {
		@include breakpoint($break-large) {
			color: inherit;
		}
	}


	.desc {
		text-align: center;
		font-family: $ff-secondary;
		margin-top: $space-half;

		> :last-child {
			margin-bottom: 0;
		}

		@include breakpoint($break-large) {
			font-size: em(26);
			@include pre(1);
			@include post(1);
			padding-bottom: $space * 1.5;
		}
	}

	.meta {
		text-align: center;
		margin-top: -7px;

		@include breakpoint($break-large) {
			color: $white;
			position: absolute;
			top: 100%;
			width: 100%;
		}

		li {
			@include breakpoint(max-width $break-large) {
				display: block;
				margin-right: 0;

				+ li {
					margin-top: $space-half;
				}
			}
		}

		.term-inline {
			font-size: em(11,13);
			border: 1px solid #fff;

			@include breakpoint(max-width $break-large) {
				margin-right: 0;
			}
		}
	}


	.image {
		width: 100%;
		height: 0;
		display: block;
		position: absolute;
		top: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: -2;

		@include breakpoint($break-large) {
			height: auto !important;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&:before {
			content: "";
			background-color: transparent;
			background-color: rgba($black, .6);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}

.has-caption {
	&:before {
		@include breakpoint($break-large) {
			content: "";
			display: block;
			width: 100%;
			background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.6));
			height: 210px;
			bottom: 0;
			position: absolute;
		}
	}
}

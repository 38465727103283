.header-nav {
  @include breakpoint(max-width $break-large) {
    display: none;
  }

  @include breakpoint($break-large) {
    position: absolute;
    top: 7px;
    right: 0;
  }

  @include breakpoint($break-xlarge) {
    top: 31px;
  }

  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    font-weight: $fw-medium;
  }

  .js-nav-open & {
    @include breakpoint(max-width $break-large) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .search-link {
    position: absolute;
    top: 1px;
  }
}

.header-menu {
  margin: 0 (-($gutter));

  @include breakpoint($break-large) {
    display: inline-block;
    margin: 0;
  }

  .js .js-search-open & {
    display: none;
  }

  // 2nd level
  ul {
    background-color: $bg-grey-light;
    font-weight: $fw-regular;

    @include breakpoint($break-large) {
      width: 379px;
    }

    a {
      padding-left: $gutter * 2;
    }

    // 2nd level on larger screens
    @include breakpoint($break-large) {
      li {
        display: block;
      }

      a {
        font-weight: $fw-regular;
        padding: 16px;
        padding-left: $space * 1.5;

        &:hover,
        &:focus {
          font-weight: $fw-bold;
        }
      }
    }
  }

  > li {
    @include breakpoint(max-width $break-large) {
      display: none;
    }

    &.expandable {
      @include breakpoint(max-width $break-large) {
        display: block;

        > a {
          display: none;
        }
      }
    }
  }

  > li > a {
    @include breakpoint($break-large) {
      border-top: none;
      padding-right: $space-one-fourth;
      padding-left: $space-one-fourth;

      &:before {
        border: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  li {
    @include breakpoint($break-large) {
      display: inline-block;
    }

    &.mobile-only {
      @include breakpoint($break-large) {
        display: none;
      }
    }
  }

  a {
    display: block;
    padding: 15px $gutter 11px;
    font-size: em(17);
    font-weight: $fw-bold;
    text-decoration: none;
    position: relative;
    border-top: 1px solid $border-color-light;

    @include breakpoint($break-large $break-xlarge) {
      padding-right: $space-one-fourth;
      padding-left: $space-one-fourth;
    }

    &:before {
      content: "";
      border-left: 5px solid transparent;
      display: inline-block;
      left: 0;
      height: 100%;
      top: 0;
      position: absolute;

      @include breakpoint($break-large) {
        left: -1px;
      }
    }
  }

  a:hover,
  a:focus,
  .active {
    color: $black;

    &:before {
      border-left-color: $color-primary;
    }
  }
}

// Editions
.editions {
  .title,
  > ul > li > a {
    background-color: $white;
    font-size: em(19);
    font-weight: 500;
    padding: ($space-half - 5) $space-half;
    padding-left: $space-three-fourth;
    margin: 0;
    border-top: 1px solid $border-color-light;
    position: relative;
    display: block;

    @include breakpoint($break-large) {
      padding: $space-half;
      padding-left: $space-three-fourth;
    }
  }

  .title {
    cursor: pointer;

    &:after {
      content: "+";
      display: block;
      position: absolute;
      right: 20.25px;
      top: em(2.5);
      font-size: em(33);
      font-weight: 100;

      @include breakpoint($break-large) {
        top: em(5.25);
      }
    }
  }

  .expanded .title:after {
    content: "—";
    right: 20px;
    top: em(7.5);
    font-size: em(25);
    font-weight: 400;

    @include breakpoint($break-large) {
      top: em(11.5);
    }
  }

  li:first-child .title {
    @include breakpoint($break-large) {
      border-top: none;
    }
  }

  > a:before {
    @include breakpoint($break-large) {
      position: static;
    }
  }
}

// Expanding menu element
.expandable {
  > ul {
    display: none;

    .js-nav-processed & {
      display: block;

      @include breakpoint($break-large) {
        display: none;
      }
    }

    @include breakpoint($break-large) {
      display: none;
      position: absolute;
      z-index: 10;
      font-weight: $fw-regular;
      font-size: em(19);
      right: 0;
      top: 82px;
      border: 1px solid $border-color-light;
      border-top: none;
      border-bottom: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
      padding-bottom: 3px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 3px;
        background-color: $color-primary;
        bottom: 0;
        left: -1px;
        right: -1px;
      }
    }

    @include breakpoint($break-xlarge) {
      top: 82px;
    }
  }

  > a {
    @include breakpoint($break-large) {
      padding-right: 0;
    }

    &:before {
      @include breakpoint($break-large) {
        content: "";
        vertical-align: middle;
        margin-top: -3px;
        margin-right: $space-one-fourth;
        @include sprite(bars);
      }
    }

    &:after {
      @include breakpoint(max-width $break-large) {
        content: '';
        float: right;
        margin-top: 3px;
        @include sprite(plus);
      }
    }
  }

  &.js-expandable-open {
    // Attemt to not make the menu move when sub menu is active
    @include breakpoint($break-large) {
      margin-left: 4.5px;
    }

    > ul {
      display: block;
    }

    > a:before {
      @include breakpoint($break-large) {
        @include sprite(close);
        margin-left: 31px; // Attempt to not make the menu move when sub menu is active
      }
    }

    > a:after {
      @include breakpoint(max-width $break-large) {
        @include sprite(minus, bg);
      }
    }
  }

  ul ul {
    display: none;
  }
  .expanded ul {
    display: block;
  }
}

// Header categories menu
$categories: (
  politikk: $dark-blue,
  helse: $light-blue,
  miljø: $green,
  sport: $yellow,
  samfunn: $orange,
  kultur: $pink,
  teknologi: $black,
  økonomi: $grey-ADADAD,
  idrett: $yellow
);
.header-categories {
  clear: both;
  width: 100%;
  border-top: 1px solid $grey-C2C2C2;
  margin-bottom: -1px;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    overflow: hidden;
    max-width: $break-xlarge;
    margin: 0 $gutter;

    @include breakpoint($break-xlarge + 15) {
      margin: 0 auto;
    }
  }
  li {
    flex-grow: 1;
    border-right: 1px solid $grey-C2C2C2;

    &:last-child {
      border-right: none;
    }
  }
  a {
    display: block;
    color: $black;
    font-size: em(13);
    line-height: 1.385;
    text-align: center;
    text-decoration: none;
    padding-top: 12px;
    padding-bottom: 13px;
    transition: all 0.4s;

    &:hover,
    &:focus {
      color: $white;
      background-color: $grey-C2C2C2;
    }
  }

  @each $cat, $bgcolor in $categories {
    .cat-#{$cat} a {
      &:hover,
      &:focus {
        color: $white;
        background-color: $bgcolor;
      }
    }
    .current-menu-item.cat-#{$cat} a {
      color: $white;
      background-color: $bgcolor;
    }
  }

  @include breakpoint(max-width $break-large) {
    display: none;
  }
}

.nav-bottom {
  text-align: center;
  padding-top: $space-half;
  background-color: $bg-grey-light;

  .container {
    margin-top: -$space-one-fourth;
  }

  .btn {
    margin-top: $space-one-fourth;
    vertical-align: top;
  }
}

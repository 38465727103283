label {
  font-size: em(12);
  display: inline-block;
  position: relative;
}

fieldset {
  + fieldset {
    margin-top: $space-one-fourth;
    display: block;
  }
}

/* Default input text form */
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
select {
  background-color: white;
  line-height: 1;
  height: 41px;
  padding-left: $space-one-eight;
  padding-right: $space-one-eight;
  border: 0;
  border: 1px solid $form-border-color;
  width: 100%;
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  -moz-appearance: none;
  font-family: $ff-secondary;

  &:focus {
    border-color: $base-color;
  }
}

textarea {
  padding-top:  $space-half;
  padding-bottom: $space-half;
  min-height: 7em;
}

/* Override default webkit input search style */
input[type="search"] {
  -webkit-appearance: none;
  padding-left: 15px;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.form-search {
  input {
    background-color: transparent;
  }
}

/* Override default placeholder text style */
::-webkit-input-placeholder {
  color: #ccc;
  line-height: 1.5;
}

:-moz-placeholder {
  color: #ccc;
  line-height: 1.5;
}
::-moz-placeholder {
  color: #ccc;
  line-height: 1.5;
}

:-ms-input-placeholder {
  color: #ccc;
  line-height: 1.5;
}


.search-form-wrapper {
  // padding-top: 12px;

  ::placeholder {
    color: $color-grey-medium;
    opacity: 1;
  }

  form {
    // position: relative;
    margin-top: 50px;
    @extend .container;

    @include breakpoint($break-medium) {
      @include squish(1);
    }

    @include breakpoint($break-large) {
      @include squish(2);
    }

    @include breakpoint($break-xlarge + 30) {
      @include squish(4);
      text-align: center;
      top: 50px;
    }
  }

  input[type="search"] {
    padding-top: 5px;
    border-color: rgba(0,0,0,0.1);
    font-family: $ff-tertiary;
    padding-bottom: 4px;
  }

  .select,
  .dropdown {
    line-height: 1.4;
    width: 100%;
    font-size: em(14);
    padding-left: 10px;
    // background: url(../images/triangle.svg) no-repeat 96% $white;
    border: 1px solid $border-color-select;
    border-radius: 3px;
    margin-bottom: 20px;

    @include breakpoint($break-medium) {
      width: 49%;
    }

    @include breakpoint($break-large) {
      // width: 39%;
    }

    @include breakpoint($break-xlarge) {
      margin-right: 1%;
    }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

  }

  .search-input-wrapper {
    position: relative;
  }

  .btn-search-white {
    display: block;
    position: absolute;
    right: 1px;
    top: 1px;
    padding-left: 20px;
    color: $white;
    border: 2px solid $white;
    background: $blue;
    font-size: em(14);
    font-weight: $fw-bold;
    font-family: $ff-tertiary;
    padding-top: 6px;


    &:hover,
    &:focus {
      opacity: .8;
      cursor: pointer;
      transform: none;
    }

    span {
      position: relative;
      top: 3px;
    }

  }

}

/* OLD SELECT (HIDDEN) */

.dropdown {
  font-family: $ff-secondary;
  position: relative;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  text-align: left;

  /* --- EASYDROPDOWN DEFAULT THEME --- */
  div,
  li,
  div::after{
  	transition: all 150ms ease-in-out;
  }

  .selected::after,
  &.scrollable div::after{
  	pointer-events: none;
  }


  &.open{
  	z-index: 2;
  }

  &:hover{
  	box-shadow: 0 0 5px rgba(0,0,0,.15);
  }

  &.focus{
  	box-shadow: 0 0 5px rgba(51,102,248,.4);
  }

  /* CARAT */
  .carat{
  	position: absolute;
  	right: 12px;
  	top: 50%;
  	margin-top: -4px;
  	border: 6px solid transparent;
  	border-top: 8px solid #000;
  }

  &.open .carat{
  	margin-top: -10px;
  	border-top: 6px solid transparent;
  	border-bottom: 8px solid #000;
  }

  &.disabled .carat{
  	border-top-color: #999;
  }

 .old{
  	position: absolute;
  	left: 0;
  	top: 0;
  	height: 0;
  	width: 0;
  	overflow: hidden;
  }

  .dropdown select{
  	position: absolute;
  	left: 0px;
  	top: 0px;
  }

  &.touch {
    .old{
      width: 100%;
      height: 100%;
    }

    select{
      width: 100%;
      height: 100%;
      opacity: 0;
    }

  }

  /* SELECTED FEEDBACK ITEM */
  .selected,
  li {
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    padding: 12px 4px;
    overflow: hidden;
    white-space: nowrap;
  }

  &.disabled .selected{
    color: #999;
  }

  .selected::after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;

    border-radius: 0 2px 2px 0;
    box-shadow: inset -55px 0 25px -20px #fff;
  }

  /* DROP DOWN WRAPPER */
  div{
    position: absolute;
    height: 0;
    left: -1px;
    right: -1px;
    top: 100%;
    margin-top: -1px;
    background: #fff;
    border: 1px solid #ccc;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
    overflow: hidden;
    opacity: 0;
  }

  /* Height is adjusted by JS on open */
  &.open div{
    opacity: 1;
    z-index: 2;
  }

  /* FADE OVERLAY FOR SCROLLING LISTS */
  &.scrollable {
    div::after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;

      box-shadow: inset 0 -50px 30px -35px #fff;
    }

    &.bottom div::after{
      opacity: 0;
    }

    &.open ul{
      overflow-y: auto;
    }
  }

  /* DROP DOWN LIST */
  ul{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    list-style: none;
    overflow: hidden;
    padding-left: 0;
    margin: 0;

    li {
      padding-left: 10px;
    }
  }

  /* DROP DOWN LIST ITEMS */
  li{
    list-style: none;
    // padding: 8px 12px;
  }

  /* .focus class is also added on hover */
  li.focus{
    background: $grey-F2F2F2;
    position: relative;
    z-index: 3;
    color: $black;
  }

  li.active{
    font-weight: 700;
  }
}

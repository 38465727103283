.page-header {
  border-bottom: 1px solid $border-color-light;
  font-family: $ff-secondary;
  line-height: 1.2;

  // When main menu is expanded on large screens
  .js-expandable-open & {
    @include breakpoint($break-large) {
      // border-bottom-color: transparent;
    }
  }

  &.js-nav-open {
    @include breakpoint(max-width $break-large) {
      border-bottom: 3px solid $black;
    }
  }

  > .container {
    position: relative;
  }
}

.header-logo {
  display: block;
  padding-top: 16px;
  padding-bottom: 18px;

  img {
    width: 180px;
  }

  @include breakpoint($break-xlarge) {
    line-height: 1;
    padding-top: 20px;
    padding-bottom: 29px;
    min-height: 112px;

    img {
      width: 32.1%;
    }
  }
}

// Week label
.week-label {
  position: absolute;
  top: 20px;
  right: 45px;

  display: inline-block;
  background-color: $color-primary;
  color: $white;
  font-size: em($fz-h6 - 3);
  padding: 7px;
  line-height: 1;
  font-weight: $fw-light;

  @include breakpoint(max-width $break-medium) {
    span span {
      display: none;
    }
  }

  @include breakpoint($break-medium) {
    padding: 7px 11px;
  }

  @include breakpoint($break-large) {
    left: 185px;
    right: auto;
  }

  @include breakpoint($break-xlarge) {
    right: inherit;
    top: 32px;
    top: 28%;
    left: auto;
    right: 362px;

    font-size: em(26);
    padding: 12px 22px 13px;
  }

  span {
    font-weight: $fw-bold;

    @include breakpoint($break-large) {
      margin-left: $space-one-eight;
    }
  }

  .js-nav-open & {
    @include breakpoint(max-width $break-large) {
      display: none;
    }
  }
}

.nav-toggle {
  font-size: em(17);
  font-weight: $fw-bold;
  text-decoration: none;
  position: absolute;
  top: 13px;
  right: -($gutter);
  display: inline-block;
  padding: 10px $gutter;
  line-height: 1;

  &:hover {
    text-decoration: underline;
    color: $black;
  }

  @include breakpoint($break-large) {
    display: none;
  }

  &:before {
    content: "";
    vertical-align: bottom;
    margin-right: $space-one-fourth;
    margin-top: -5px;
    @include sprite(bars);

    .js-nav-open & {
      vertical-align: baseline;
      @include sprite(close);
    }
  }
}

// Add white opacity background when child menu item is active
.js-expandable-open {
  .page-body,
  .page-footer,
  .header-categories {
    @include breakpoint($break-large) {
      position: relative;

      &:before {
        content: "";
        background-color: $white;
        background-color: rgba($white, .9);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
      }
    }
  }
}

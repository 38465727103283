///////////
// Color //
///////////
$black: #000;
$white: #fff;
$red: #D10808;
$purple: #7309B1;
$pink: #B80066;
$green: #247A66;
$blue: #2C2D9A;
$dark-blue: #0076FF;
$light-blue: #00A3A3;
$yellow: #C9B72C;
$orange: #BE7300;
$grey-222222: #222222;
$grey-333333: #333333;
$grey-555555: #555555;
$grey-686868: #686868;
$grey-777777: #777777;
$grey-979797: #979797;
$grey-ADADAD: #ADADAD; // Used on article dotted line
$grey-C2C2C2: #C2C2C2;
$grey-D8D8D8: #D8D8D8;
$grey-DDDDDD: #DDDDDD;
$grey-E3E3E3: #E3E3E3;
$grey-F2F2F2: #F2F2F2;
$grey-F5F6EE: #F5F6EE;
$grey-F7F7F7: #F7F7F7;
$grey-F9F9F9: #F9F9F9;

$color-primary: $red;
$color-secondary: $black;
$color-tertiary: dodgerblue;
$color-quaternary: sand;
$color-quinary: pink;
$color-grey: $grey-333333;
$color-grey-medium: $grey-555555;
$color-grey-light: $grey-777777;

$border-color-dark: $grey-777777;
$border-color-select: $grey-979797;
$border-color-dark2: $grey-333333;
$border-color: $grey-C2C2C2;
$border-color-medium: $grey-DDDDDD;
$border-color-light: $grey-E3E3E3;

$bg-grey-green: $grey-F5F6EE;
$bg-grey-light: $grey-F2F2F2;
$bg-red: $red;
$bg-black: $black;

////////////////
// Typography //
////////////////
$fz: 19;
$fz-small: $fz;
$fz-h1: 60;
$fz-h1-small: 36;
$fz-h2: 24;
$fz-h3: 22;
$fz-h4: 20;
$fz-h5: $fz;
$fz-h6: 15;


// Line height
$lh: 1.58;
$lh-h1: 1.2;
$lh-h2: 1.2;
$lh-h3: 1.2;
$lh-h4: 1.2;
$lh-h5: 1.2;
$lh-h6: 1.2;

// Font family
$ff-primary: "Crimson", Georgia, serif;
$ff-secondary: "Roboto", Arial, sans-serif;
$ff-tertiary: "Arimo", Arial, sans-serif;

// Font weight
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 600;

/////////////////
// Grid layout //
/////////////////
// Susy map
$susy: (
  columns: 12,
  gutters: .422535211,
  global-box-sizing: border-box,
  // debug: (image: show)
);


// Additional susy maps
$layout-medium: layout(6 .5 false);
$layout-small: layout(4 1 false);


/////////////
// Spacing //
/////////////
$space: 38px; // Base font size (19px) * 2
$space-one-eight: $space * 0.125;
$space-one-fourth: $space * 0.25;
$space-half: $space * 0.5;
$space-three-fourth: $space * .75;
$space-double: $space * 2;
$space-triple: $space * 3;
$space-quadruple: $space * 4;

$header-color: $grey-222222;
$header-color-secondary: $grey-686868;

$base-fw: $fw-regular;
$base-fz: ($fz/16) * 100%;
$base-fz-small: ($fz-small/16) * 100%;
$base-lh: $lh;
$base-color: $grey-333333;
$base-color-grey: $grey-777777;
$base-ff: $ff-primary;
$base-border-color: $border-color;

// Base
$headers-ff: $ff-secondary; // Font-family headers
$text-color: $grey-333333;
$link-color: $color-primary;
$border-radius: 5px;

////////////////
// Breakpoint //
////////////////
$break-small: 20em;
$break-medium: 33em;
$break-large: 45em; // 767px
$break-xlarge: 62.211em; // 1182px / 19

// Layout
$gutter: $space-half;

////////////
// Sprite //
////////////
@import "map-sprite-autocompiled";
// Add class for ´.no-svg sprite fallback
$pngSprite: '.no-svg' !default;
// Set the `$sprite` map
$sprite: map-get($icons, sprite) !default;

/////////////
// Modules //
/////////////
// Buttons
$btn-color: $color-primary;
$btn-color-primary: $btn-color;

// Forms
$form-border-color: $border-color;

// Table
$table-thead-bg: $grey-C2C2C2;
$table-border-color: $grey-E3E3E3;

// Contentbox
$contentbox-badge-bg: $purple;
$contentbox-border-color: $purple;

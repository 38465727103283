.social-share {
  @extend .list-inline;
  // @extend .center;

  a {
    &:hover,
    &:focus {
      opacity: .85;
    }
  }

  li {
    margin-right: $space-one-eight;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Social colors
.bg-facebook {
  $color-facebook: #3a5795;
  background-color: $color-facebook !important;
}

.bg-twitter {
  $color-twitter: #55acee;
  background-color: $color-twitter !important;
}

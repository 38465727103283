.container {
  @extend .clearfix;
  max-width: $break-xlarge;
  margin-left: $gutter;
  margin-right: $gutter;
  @include show-grid();

  @include breakpoint($break-xlarge + 15) {
    margin-left: auto;
    margin-right: auto;
  }
}

.page-body {
  margin-bottom: $space;

  @include breakpoint($break-large) {
    margin-bottom: $space * 1.5;
  }
}


.pagination {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);

  li {
  display: inline;

    a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: underline;

      &.active,
      &.current {
        font-weight: $fw-bold;
        text-decoration: none;
        cursor: default;
      }

      &:hover:not(.current) {
        background-color: scale-color($blue, $lightness: 90%);
        text-decoration: none;
      }
    }
  }
}

// Set global box sizing (Susy mixin)
@include global-box-sizing(border-box);

html {
  font-size: $base-fz;
  color: $base-color;
  line-height: $base-lh;
  font-family: $base-ff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $white;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

 ::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

 hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

 audio,
 canvas,
 iframe,
 img,
 svg,
 video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

 fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

 textarea {
  resize: vertical;
}

figure {
  margin-left: 0;
  margin-right: 0;

  .article-body & {
    margin: $space 0;
  }

  // Context: Main content in Article template
  .template-article .main-content & {
    margin-left: -($gutter);
    margin-right: -($gutter);
    text-align: center;

    figcaption {
      font-family: $ff-primary;
      @include breakpoint(max-width $break-large) {
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }

    @include breakpoint($break-large) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .template-article .article-body & {
    img {
      width: 100%;
    }
  }
}

figcaption {
  font-size: em($fz-h6);
  margin-top: 15px;
  font-family: $ff-secondary;
  font-weight: $fw-light;
  line-height: 1.4;
  color: $color-grey-medium;

  p {
    margin-bottom: .35em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .credit {
    font-size: em(11, $fz-h6);
    color: $color-grey-light;
    margin-top: $space-one-fourth;
  }
}

img {
  max-width: 100%;
  height: auto;
}

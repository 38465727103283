img,
figure {
  &.alignleft {
    @include breakpoint($break-large) {
      float: left;
      clear: left;
      display: inline;
      margin: ($space-one-fourth - 4) $space-half $space-one-eight 0;
      width: 45%;
    }
  }

  &.alignright {
    @include breakpoint($break-large) {
      float: right;
      clear: right;
  		display: inline;
      margin: ($space-one-fourth - 2) 0 $space-one-eight $space-half;
      width: 45%;
    }
  }

  &.aligncenter {
    @include breakpoint($break-large) {
      clear: both;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }
}

.accordion-group {

}

.accordion {
  .toggle {
    text-decoration: none;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }

    // Style when javascript is available
    .js & {
      &:before {
        content: "";
        @include sprite(triangle-down);
        float: left;
        margin-top: 6px;
        margin-right: 20px;
      }
    }

    .title {
      margin-bottom: 0;
    }
  }

  .accordion-body {
    margin-top: $space-three-fourth;
    // opacity: 1;
    // visibility: visible;
    // max-height: 999999px;
    // transition: all .25s ease-in-out;
  }

  &.collapsed {
    .toggle:before {
      transform: rotate(-90deg);
    }

    .accordion-body {
      display: none;
      // opacity: 0;
      // visibility: hidden;
      // max-height: 0;
    }
  }
}

@media print,
       (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 1.25dppx),
       (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    html {
      font-size: 80%;
    }

    // figure {
    //   max-width: 50%;
    // }

    // *,
    // *:before,
    // *:after,
    // *:first-letter,
    // *:first-line {
    //     background: transparent !important;
    //     color: #000 !important; /* Black prints faster:
    //                                http://www.sanbeiji.com/archives/953 */
    //     box-shadow: none !important;
    //     text-shadow: none !important;
    // }

    a,
    a:visited {
        text-decoration: underline;
    }

    // a[href]:after {
    //     content: " (" attr(href) ")";
    // }
    //
    // abbr[title]:after {
    //     content: " (" attr(title) ")";
    // }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@media print {
  .page-header,
  .page-aside,
  .page-aside + .section,
  .page-footer,
  .article-footer,
  .social-share,
  .richmedia,
  .main-aside .section:nth-child(2),
  .template-article .section-mostread,
  .nav-bottom,
  .expandable-media .toggle {
    display: none;
  }

  .hero-figure {
    max-width: $break-xlarge;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: $space !important;

    &:before {
      content: none;
    }

    .image {
      max-height: 447px;
      -webkit-print-color-adjust: exact;
    }

    figcaption {
      position: static;
      color: inherit;
      margin: 20px auto 0;
      text-align: center;
      font-size: 1.1em;
    }
  }
  iframe,
  .video-wrapper {
    display: none !important;
  }
}

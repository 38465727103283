.template-issue-page {
  .page-body {
    margin-bottom: 0;
  }

  .hero-primary {
    margin-bottom: $space-double;

    .container {
      text-align: center;
      max-width: $break-large;
    }
    .desc {
      line-height: 2;

      p, ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
    .special {
      position: relative;
      display: inline-block;
      top: 50px;
      font-family: $ff-secondary;
      font-size: 15px;
      padding: 4px 12px;
      border: 1px solid white;
      color: white;
      text-transform: uppercase;
    }
    .btn-primary {
      display: block;
      border-bottom: 5px solid darken($red, 10%);
      color: white;
    }
    @include breakpoint($break-small) {
      margin-bottom: $space;
      .btn-primary {
        width: 50%;
        margin: 20px auto;
      }
    }
    @include breakpoint($break-medium) {
      margin-bottom: $space;
      .container {
        margin: auto;
      }
      .btn-primary {
        width: 30%;
      }
    }
    @include breakpoint($break-large) {
      margin-bottom: 0;
      .desc {
        font-size: em(22);
      }
      .btn-primary {
        margin: -20px auto -40px auto;;
        width: 30%;
      }
    }
  }

  .hero-primary + .section-fullwidth {
    padding-top: 0;

    .section-body {
      @include breakpoint($break-large) {
        padding-top: $space * 1.5;
      }
    }
  }

  .main-title {
    color: $white;
    margin: 0;
    font-size: em($fz-h5);
    font-weight: $fw-light;
    line-height: 43px;

    @include breakpoint($break-large) {
      font-size: em(26);
      line-height: 59px;
    }
  }

  .main-header {
    background-color: $pink;

    @include breakpoint($break-large) {
      margin-bottom: $space * 1.5;
    }
  }
}

.template-basic-page {
  .page-body {
    margin-bottom: $space-half;

    @include breakpoint($break-large) {
      margin-bottom: $space;
    }
  }

  .main-title {
    margin-top: $space-double;
    margin-bottom: $space-half;

    @include breakpoint($break-large) {
      text-align: center;
      margin-bottom: $space * 1.5;
    }
  }

  .main-body {
    @include breakpoint($break-large) {
      @include pre(2);
      @include post(2);
    }
  }
}

%contentbox-badge {
  content: "i";
  background-color: $contentbox-badge-bg;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  line-height: 47px;
  text-align: center;
  display: inline-block;
  color: $white;
  font-size: 22px;
  font-weight: $fw-bold;
  box-shadow: 0 0 0 2px white;
  position: absolute;
  top: -22px;
  right: $space-half;

  @include breakpoint($break-large) {
    right: auto;
    left: -22px;
  }
}

.contentbox {
  padding: 20px 24px;
  font-size: em($fz-h6);
  position: relative;
  line-height: 1.7;

  + .contentbox {
    margin-top: $space-one-fourth;

    @include breakpoint($break-large) {
      margin-top: $space-half;
    }
  }

  .main-body & {
    margin: $space 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ul, ol {
    padding-left: 1em;
  }

  li + li {
    margin-top: .5em;
  }

  .title {
    font-size: em($fz-h5, $fz-h6);
    line-height: 1;
    position: relative;
    margin: 0;
    padding-top: 5px;
  }
}

//
// Variations
//
.contentbox {
  // Factbox
  &.facts {
    border: 1px solid $border-color-dark;
    border-bottom: 3px solid $contentbox-border-color;
    border-radius: 2px;

    .title {
      font-weight: $fw-bold;
      font-family: $ff-secondary;
    }

    &:before {
      @extend %contentbox-badge;
    }
  }

  // Questions
  &.questions {
    border: 2px solid $contentbox-border-color;
    font-size: em(17);

    &:before {
      @extend %contentbox-badge;
      content: "?";
      font-family: $ff-secondary;
      line-height: 44px;
    }
  }

  // Greybox
  &.greybox {
    $fz-body: 13;
    background-color: $bg-grey-light;
    font-size: em($fz-body);

    .title {
      font-family: $ff-primary;
      font-weight: $fw-regular;
      font-size: em($fz-h5, $fz-body);
    }

    .title-secondary {
      @extend .title;
      font-size: em($fz-h6, $fz-body);
      margin-top: $space-three-fourth;
      margin-bottom: $space-half;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      &:before {
        content: "\00b7";
        margin-right: $space-one-eight;
      }

      + li {
        margin-top: $space-half;
      }
    }
  }
}

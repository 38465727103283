.article-teaser {
	.term {
		position: relative;
		line-height: 1;
		margin-bottom: -11px;
		margin-left: $gutter;

		@include breakpoint($break-medium) {
			margin-left: 15px;
		}

		.inner {
			@extend .term-label;
			box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		}
	}

	.link-wrapper {
		&:hover, &:focus {
			.title {
				text-decoration: underline;
			}
		}
	}

	.title {
		font-size: em($fz-h2);
		line-height: 1.3;
		font-family: $ff-secondary;
		margin-top: $space-half;
		margin-bottom: $space-one-fourth;

		@include breakpoint($break-large) {
			font-size: em(30);
			line-height: 1.4;
		}
	}

	.desc {
		font-size: em(17);
		line-height: 1.6;

		> :last-child {
			margin-bottom: 0;
		}
	}

	.meta {
		margin-top: $space-half;

		@include breakpoint($break-large) {
			margin-top: $space-one-fourth;
		}
	}

	.mod-offset-small_article-teaser & {
		@include breakpoint(max-width $break-medium - 1) {
			margin-left: -$gutter;
			margin-right: -$gutter;

			.title,
			.desc,
			.meta {
				margin-left: $gutter;
				margin-right: $gutter;
			}
		}
	}

	.image + .label-has-video {
		margin-top: -88px;
	}

	.label-has-video {
		display: block;
		background: orange;
		background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.4));
		height: 88px;
		position: relative;

		&:before {
			content: "";
			@include sprite(video);
			position: absolute;
			right: $space-half;
			bottom: $space-half;
		}
	}
}

.template-search {
  .main-title {
    margin-top: 50px;
    margin-bottom: 65px;
    text-align: center;
    font-size: em(16);
    font-family: $ff-secondary;

    h1 {
      margin: 0;
      margin-right: 20px;
      display: inline-block;
      font-size: em($fz-h2);
    }
  }

  .main-footer {
    margin-top: $space-triple;
  }
}

.template-category {
  .main-title {
    color: $white;
    margin: 0;
    font-size: em($fz-h5);
    font-weight: $fw-light;
    line-height: 43px;

    @include breakpoint($break-large) {
      font-size: em(26);
      line-height: 59px;
    }
  }

  .main-header {
    background-color: $bg-black;

    @include breakpoint($break-large) {
      margin-bottom: $space * 1.5;
    }
  }

  .article-teaser .term {
    display: none;
  }
}

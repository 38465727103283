h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 1.5em;
  margin-bottom: .3em;
  font-weight: $fw-bold;
  font-family: $headers-ff;
  color: $header-color;
}

h1, .h1 {
  font-size: em($fz-h1-small);
  line-height: $lh-h1;

  @include breakpoint($break-large) {
    font-size: em($fz-h1);
  }
}

h2, .h2 {
  font-size: em($fz-h2);
  line-height: $lh-h2;
}

h3, .h3 {
  font-size: em($fz-h3);
  line-height: $lh-h3;
  color: $header-color-secondary;
}

h4, .h4 {
  font-size: em($fz-h4);
  line-height: $lh-h4;
}

h5, .h5 {
  font-size: em($fz-h5);
  line-height: $lh-h5;
}

h6, .h6 {
  font-size: em($fz-h6);
  line-height: $lh-h6;
}

p {
  margin: 0 0 .75em;
}

a {
  color: $text-color;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $link-color;
  }
}

blockquote {
  font-family: $ff-primary;
  font-weight: $fw-regular;
  font-size: em($fz-h2);
  font-style: italic;
  margin: $space-double 0 $space 0;
  color: $base-color;
  line-height: 1.6;
  text-align: center;

  p {
    &:first-of-type:before {
      content: "«";
    }

    &:last-of-type:after {
      content: "»";
    }
  }

  .source {
    font-weight: $fw-light;
    font-style: normal;
    font-size: em(13, $fz-h2);
    display: block;
    text-transform: uppercase;

    &:before {
      content: "–";
    }
  }
}

.title-boxed {
	font-family: $ff-primary;
	font-size: em($fz-h6);
	font-weight: $fw-bold;
	text-transform: uppercase;
	text-align: center;
	margin: 0;
  position: relative;
  line-height: 1;

  a {
    color: $color-primary;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

	.inner {
		padding: 12px 19px 6px;
		background-color: $white;
		border: 1px solid $border-color-dark;
		display: inline-block;
		letter-spacing: .141em;
    color: $base-color;

    &:before {
      content: "";
      display: block;
      border-bottom: 1px solid $border-color-dark;
      position: absolute;
      width: 100%;
      left: 0;
      top: 17px;
      z-index: -1;
    }
	}
}


.main-lead {
  font-family: $ff-secondary;
  font-size: em($fz-h4);
  font-weight: $fw-regular;
  line-height: 1.6;

  @include breakpoint($break-large) {
    font-size: em($fz-h2);
  }

  >:last-child {
    margin-bottom: 0;
  }

  .term-inline {
    font-size: em(11, $fz-h4);
    top: -.3em;

    @include breakpoint($break-large) {
      font-size: em(11, $fz-h2);
      top: -.4em;
    }
  }
}

.skip-link {
	display: block;
	text-align: center;
	font-family: $ff-secondary;
	position: absolute !important;
	width: 100% !important;
	font-size: em(14);
	background-color: $color-primary;
	color: $white !important;
	line-height: 1;
	padding: 6px 0 4px;
	z-index: 10;

	&:hover,
	&:focus {
		text-decoration: underline;
		outline: 0;
	}
}

/* Do not show the outline on the skip link target. */
#page-body[tabindex="-1"]:focus {
	outline: 0;
}

// Visability
// Hide only visually, but have it available for screenreaders: h5bp.com/v
.visuallyhidden {
  @include visuallyhidden;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p
  &.focusable:active,
  &.focusable:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
  }
}

.invisible { visibility: hidden; }

.clearfix {
  @include clearfix;
}

.circle {
  border-radius: 50%;
}

.center {
  text-align: center;
}

a.link-wrapper {
  display: block;
  text-decoration: none !important;

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit !important;
  }
}

.small-only {
  @include breakpoint($break-medium) {
    display: none !important;
  }
}

.large-up {
  @include breakpoint(max-width $break-large) {
    display: none !important;
  }
}

.large-down {
  @include breakpoint($break-large) {
    display: none !important;
  }
}

.list-inline {
  padding-left: 0;
  list-style: none;

  li {
    display: inline-block;

    + li {
      margin-top: 0;
    }
  }
}

.list-float {
  padding-left: 0;
  list-style: none;
  @extend .clearfix;

  li {
    float: left;

    + li {
      margin-top: 0;
    }
  }
}

$icons: (
    sprite: (width: 114px, height: 96px, pngPath: '../sprite/sprite.png', svgPath: '../sprite/sprite.svg'),
   bars: (width: 25px, height: 15px, backgroundX: -86px, backgroundY: -28px),
   chevron-down: (width: 20px, height: 12px, backgroundX: -51px, backgroundY: -35px),
   chevron-up-small: (width: 11px, height: 8px, backgroundX: -99px, backgroundY: -72px),
   circle-plus: (width: 35px, height: 35px, backgroundX: -51px, backgroundY: 0px),
   close: (width: 12px, height: 12px, backgroundX: -87px, backgroundY: -72px),
   facebook: (width: 24px, height: 24px, backgroundX: -86px, backgroundY: -43px),
   facebook-white: (width: 24px, height: 24px, backgroundX: -24px, backgroundY: -72px),
   minus: (width: 16px, height: 16px, backgroundX: -49px, backgroundY: -51px),
   plus: (width: 16px, height: 16px, backgroundX: -65px, backgroundY: -51px),
   search: (width: 28px, height: 28px, backgroundX: -86px, backgroundY: 0px),
   search-black: (width: 19px, height: 19px, backgroundX: -30px, backgroundY: -51px),
   search-white: (width: 15px, height: 16px, backgroundX: -72px, backgroundY: -72px),
   triangle-down: (width: 13px, height: 11px, backgroundX: -71px, backgroundY: -35px),
   twitter: (width: 24px, height: 24px, backgroundX: -48px, backgroundY: -72px),
   twitter-white: (width: 24px, height: 24px, backgroundX: 0px, backgroundY: -72px),
   video: (width: 30px, height: 21px, backgroundX: 0px, backgroundY: -51px),
   viral: (width: 51px, height: 51px, backgroundX: 0px, backgroundY: 0px),
);
